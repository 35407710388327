<template>
  <v-form ref="form" @submit.prevent="submit" pa-0 fluid class="policy-information-form">
    <v-layout row wrap class="policy-information-form__content">
      <div class="policy-information-form__content__inner">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput('policyNumber', arguments[0])"
          :value="policyNumber"
          :rules="['required', 'maxlen250']"
          label="Policy Number"
        />
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput('effectiveDate', arguments[0])"
          :value="effectiveDate"
          :rules="['required', 'effectiveDate']"
          label="Effective Date"
          type="date"
        />
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput('expiredDate', arguments[0])"
          :value="expiredDate"
          :rules="['required', 'expiredDate']"
          label="Expiration Date"
          type="date"
        />
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput('carrier', arguments[0])"
          :value="carrier"
          :rules="['maxlen250']"
          label="Carrier"
        />
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput('deductible', arguments[0])"
          :value="deductible"
          :rules="['maxlen250']"
          label="Deductible"
        />
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput('coinsurance', arguments[0])"
          :value="coinsurance"
          :rules="['maxlen250']"
          label="Coinsurance"
        />
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput('benefitPeriod', arguments[0])"
          :value="benefitPeriod"
          :rules="['maxlen250']"
          label="Benefit Period"
        />
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput('perInjuryLimit', arguments[0])"
          :value="perInjuryLimit"
          :rules="['maxlen250']"
          label="Per Injury Limit"
        />
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput('physicalTherapySublimit', arguments[0])"
          :value="physicalTherapySublimit"
          :rules="['maxlen250']"
          label="Physical Therapy Sub Limit"
        />
        <v-select
          @input="handleFieldInput('tpaName', arguments[0])"
          :value="tpaName"
          :items="tpaOptions"
          label="TPA Name"
        />
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput('email', arguments[0])"
          :value="email"
          :rules="['email']"
          label="TPA Email"
          type="email"
        />
      </div>
      <p class="policy-information-form__submit">
        <v-btn @click="$emit('close')" outlined :loading="loading">
          Cancel
        </v-btn>
        <v-btn @click="submit" :loading="loading">
          Submit
        </v-btn>
      </p>
    </v-layout>
  </v-form>
</template>

<script>
import { syncForm } from '@/mixins';
import { genders, organizationRoles } from '@/utils/options';
import validateForm from '@/mixins/validateForm';
import { POLICY_TPA_NAMES } from '../../utils/constants';

export default {
  name: 'PolicyInformationForm',
  mixins: [syncForm, validateForm],
  props: {
    loading: Boolean,
    policyNumber: {
      type: String,
      default: () => '',
    },
    effectiveDate: {
      type: String,
      default: () => '',
    },
    expiredDate: {
      type: String,
      default: () => '',
    },
    carrier: {
      type: String,
      default: () => '',
    },
    deductible: {
      type: String,
      default: () => '',
    },
    coinsurance: {
      type: String,
      default: () => '',
    },
    benefitPeriod: {
      type: String,
      default: () => '',
    },
    perInjuryLimit: {
      type: String,
      default: () => '',
    },
    physicalTherapySublimit: {
      type: String,
      default: () => '',
    },
    tpaName: {
      type: String,
      default: () => '',
    },
    email: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      selectOptions: {
        genders,
        organizationRoles,
      },
      tpaOptions: POLICY_TPA_NAMES,
    };
  },
};
</script>

<style lang="scss">
.policy-information-form {
  &__content {
    max-width: 380px;
    margin: 0 auto;
    padding: 8px 0;

    &__inner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
    }
  }

  &__submit {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      margin: 0 10px;
    }
  }
}
</style>
