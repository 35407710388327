<template>
  <PhDialog
    :value="show"
    fullscreen
    :shadowContentScroll="true"
    @input="closePolicyInformationModal"
    closeBtnText="ADD"
    hideFooter
    class="policy-information-modal"
  >
    <h1 class="policy-information-modal__header" slot="header">
      Policy Information
    </h1>
    <div slot="content" class="policy-information-modal__content">
      <PolicyInformationForm
        v-if="!!editPolicyId"
        v-bind.sync="editForm"
        @close="hideEditForm"
        @submit="submitEditForm"
        :loading="loading"
        disable-pagination
      />
      <div class="policy-information-modal__table">
        <PhTable :headers="headers" :items="policys">
          <template v-slot:item="{ item }">
            <tr class="policy-information-modal__table-row">
              <td class="policy-information-modal__table-row-cell">
                <span class="policy-information-modal__table-row-cell-content">
                  {{ item.policyNumber }}
                </span>
              </td>
              <td class="policy-information-modal__table-row-cell">
                <span class="policy-information-modal__table-row-cell-content">
                  {{ item.effectiveDate }}
                </span>
              </td>
              <td class="policy-information-modal__table-row-cell">
                <span class="policy-information-modal__table-row-cell-content">
                  {{ item.expiredDate }}
                </span>
              </td>
              <td class="policy-information-modal__table-row-cell">
                <span class="policy-information-modal__table-row-cell-content">
                  {{ item.carrier }}
                </span>
              </td>
              <td class="policy-information-modal__table-row-cell">
                <span class="policy-information-modal__table-row-cell-content">
                  {{ item.deductible }}
                </span>
              </td>
              <td class="policy-information-modal__table-row-cell">
                <span class="policy-information-modal__table-row-cell-content">
                  {{ item.coinsurance }}
                </span>
              </td>
              <td class="policy-information-modal__table-row-cell">
                <span class="policy-information-modal__table-row-cell-content">
                  {{ item.benefitPeriod }}
                </span>
              </td>
              <td class="policy-information-modal__table-row-cell">
                <span class="policy-information-modal__table-row-cell-content">
                  {{ item.perInjuryLimit }}
                </span>
              </td>
              <td class="policy-information-modal__table-row-cell">
                <span class="policy-information-modal__table-row-cell-content">
                  {{ item.physicalTherapySublimit }}
                </span>
              </td>
              <td class="policy-information-modal__table-row-cell">
                <span class="policy-information-modal__table-row-cell-content">
                  {{ item.tpaName }}
                </span>
              </td>
              <td class="policy-information-modal__table-row-cell">
                <span class="policy-information-modal__table-row-cell-content">
                  {{ item.email }}
                </span>
              </td>
              <td
                class="policy-information-modal__table-row-cell policy-information-modal__table-row-cell--action"
              >
                <span
                  class="policy-information-modal__table-row-cell-content policy-information-modal__table-row-cell-content--justify-end"
                >
                  <v-icon title="edit" @click="handleEditPolicy(item)" size="20" class="mx-2">
                    edit
                  </v-icon>
                </span>
              </td>
            </tr>
          </template>
        </PhTable>
      </div>
    </div>
  </PhDialog>
</template>

<script>
import moment from 'moment';
import PhDialog from '@/components/modals/PhDialog';
import PhTable from '@/components/tables/PhTable';
import PolicyInformationForm from '@/components/forms/PolicyInformationForm';

const HEADERS = [
  {
    text: 'Policy Number',
    value: 'policyNumber',
    sortable: false,
    width: 80,
  },
  {
    text: 'Effective Date',
    value: 'effectiveDate',
    sortable: false,
    width: 80,
  },
  {
    text: 'Expiration Date',
    value: 'expiredDate',
    sortable: false,
    width: 80,
  },
  {
    text: 'Carrier',
    value: 'carrier',
    sortable: false,
    width: 80,
  },
  {
    text: 'Deductible',
    value: 'deductible',
    sortable: false,
    width: 80,
  },
  {
    text: 'Coinsurance',
    value: 'coinsurance',
    sortable: false,
    width: 80,
  },
  {
    text: 'Benefit Period',
    value: 'benefitPeriod',
    sortable: false,
    width: 80,
  },
  {
    text: 'Per Injury Limit',
    value: 'perInjuryLimit',
    sortable: false,
    width: 80,
  },
  {
    text: 'Physical Therapy Sub Limit',
    value: 'physicalTherapySublimit',
    sortable: false,
    width: 80,
  },
  {
    text: 'TPA Name',
    value: 'tpaName',
    sortable: false,
    width: 80,
  },
  {
    text: 'TPA Email',
    value: 'email',
    sortable: false,
    width: 80,
  },
  {
    text: '',
    value: 'action',
    sortable: false,
    width: 10,
  },
];

const PI_FORM = {
  policyNumber: '',
  effectiveDate: '',
  expiredDate: '',
  carrier: '',
  deductible: '',
  coinsurance: '',
  benefitPeriod: '',
  perInjuryLimit: '',
  physicalTherapySublimit: '',
  tpaName: '',
  email: '',
};

export default {
  name: 'PolicyInformationDialog',
  props: {
    show: Boolean,
    loading: Boolean,
    policys: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    PolicyInformationForm,
    PhDialog,
    PhTable,
  },
  data: () => ({
    editPolicyId: null,
    editForm: { ...PI_FORM },
    headers: HEADERS,
  }),
  methods: {
    hideEditForm() {
      this.editPolicyId = null;
    },
    showEditForm(id) {
      this.editPolicyId = id;
    },
    closePolicyInformationModal() {
      this.$emit('closeDialog');
      this.hideEditForm();
      this.resetEditForm();
    },
    submitEditForm() {
      this.$emit('editPolicy', {
        id: this.editPolicyId,
        payload: this.editForm,
        callback: () => {
          this.hideEditForm();
          this.resetEditForm();
        },
      });
    },
    handleEditPolicy(item) {
      const {
        id,
        policyNumber,
        effectiveDate,
        expiredDate,
        carrier,
        deductible,
        coinsurance,
        benefitPeriod,
        perInjuryLimit,
        physicalTherapySublimit,
        tpaName,
        email,
        brokerId,
      } = item;
      this.editForm = {
        ...this.editForm,
        id,
        policyNumber,
        effectiveDate: moment(effectiveDate).format('YYYY-MM-DD'),
        expiredDate: moment(expiredDate).format('YYYY-MM-DD'),
        carrier,
        deductible,
        coinsurance,
        benefitPeriod,
        perInjuryLimit,
        physicalTherapySublimit,
        tpaName,
        email,
        brokerId,
      };
      this.showEditForm(id);
    },
    resetEditForm() {
      this.editForm = Object.assign({}, { ...PI_FORM });
    },
  },
};
</script>

<style lang="scss">
$block: 'policy-information-modal';

.#{$block} {
  &__form-title {
    text-align: left;
  }
  &__content {
    height: 100%;
    overflow-y: auto;
  }
  &__header {
    text-transform: capitalize;
  }
  &__table {
    &-row {
      &-cell {
        &-content {
          display: flex;
          &--justify-end {
            display: flex;
            justify-content: flex-end;
          }
        }
        &--disabled {
          pointer-events: none;
          opacity: 0.65;
        }
      }
    }
  }
}
</style>
