<template>
  <v-container fill-height>
    <div class="organizations">
      <v-layout
        class="organizations__header-row organizations__header-row--top"
        align-center
        justify-space-between
      >
        <PageTitle>Broker Organizations</PageTitle>
        <div>
          <access-control action="createBrokerOrganization">
            <v-btn v-if="computedOrgs.length" @click="handleShowOrgCreate">
              + ADD BROKER ORG
            </v-btn>
          </access-control>
        </div>
      </v-layout>
      <v-layout align-center justify-space-between class="organizations__filter-bar">
        <PageFilterBar>
          <PageFilterGroup grow>
            <PageFilter type="search" wide>
              <SearchTextField v-model="orgSearchQuery" placeholder="Find an organization">
              </SearchTextField>
            </PageFilter>
          </PageFilterGroup>
        </PageFilterBar>
      </v-layout>
      <v-layout class="organizations__layout-wrapper">
        <transition name="fade" appear>
          <div v-if="loadingBrokerOrganizations" class="organizations__preloader-wrapper">
            <Preloader :value="loadingBrokerOrganizations" transparent></Preloader>
          </div>
        </transition>
        <v-layout v-if="computedOrgs.length" class="organizations__layout">
          <OrgCard
            v-for="org in computedOrgs"
            :key="org.id"
            :org="org"
            @showOrg="handleShowOrganization"
          ></OrgCard>
        </v-layout>
        <div v-else-if="brokerOrganizationsLoaded" class="organizations__placeholder-wrapper">
          <ViewPlaceholder type="orgs">
            <access-control action="createBrokerOrganization">
              <v-btn @click="handleShowOrgCreate">
                + ADD BROKER ORG
              </v-btn>
            </access-control>
          </ViewPlaceholder>
        </div>
      </v-layout>
    </div>
    <template v-if="updateOrgDialog.type !== 'brokerAdmin'">
      <OrgCreate
        :showOrgCreate="updateOrgDialog.show"
        :orgForm="brokerOrgForm"
        :type="'broker'"
        :loading="orgLoading"
        :showOrgType="updateOrgDialog.type"
        @flushForm="handleFlushForm"
        @orgFormLogoUpdate="handleUserOrgFileInput"
        @orgFormUpdate="handleUpdateBrokerOrgForm"
        @orgFormAddContact="handleAddBrokerContact"
        @orgFormRemoveContact="handleRemoveBrokerContact"
        @closeOrgCreateDialog="handleCloseOrgForm"
        @submit="handleSubmitOrgForm"
        @prepareContentToEdit="handlePrepareContentToEdit"
      >
      </OrgCreate>
    </template>
    <template v-if="updateOrgDialog.type === 'brokerAdmin'">
      <BrokerAdminDialog
        :show="updateOrgDialog.show"
        :loading="orgLoading"
        :brokerAdmins="sidebarContent.users"
        @editAdmin="handleEditBrokerAdmin"
        @resendInvite="handleResendInvite"
        @activateAdmin="handleActivateBrokerAdmin"
        @removeAdmin="handleRemoveBrokerAdmin"
        @closeDialog="handleCloseBrokerAdminDialog"
      />
    </template>
    <PolicyInformationDialog
      :show="updatePolicyDialog.show"
      :loading="orgLoading"
      :policys="sidebarContent.policys"
      @editPolicy="handleEditPolicyInformation"
      @closeDialog="handleClosePolicyInformationDialog"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { PageFilter, PageFilterBar, PageFilterGroup, SearchTextField } from '@/components/filters';
import PageTitle from '@/components/typography/PageTitle';
import Preloader from '@/components/Preloader';
import OrgCard from '@/components/OrgCard';
import OrgCreate from '@/components/modals/OrgCreate/index';
import ViewPlaceholder from '@/components/ViewPlaceholder';
import orgFormData from '@/mixins/orgFormData';
import BrokerAdminDialog from '@/components/modals/BrokerAdmin/index';
import PolicyInformationDialog from '@/components/modals/PolicyInformation/index';

export default {
  name: 'BrokerOrganizations',
  components: {
    OrgCard,
    OrgCreate,
    PageTitle,
    PageFilter,
    PageFilterBar,
    PageFilterGroup,
    Preloader,
    SearchTextField,
    ViewPlaceholder,
    BrokerAdminDialog,
    PolicyInformationDialog,
  },
  mixins: [orgFormData],
  data() {
    return {
      orgSearchQuery: '',
      orgLoading: false,
    };
  },
  computed: {
    ...mapGetters('user', ['userBrokerOrganizations', 'profile']),
    ...mapGetters('app', ['updateOrgDialog', 'infoSidebarData', 'updatePolicyDialog']),
    ...mapGetters('brokerOrganizations', [
      'brokerOrganizations',
      'brokerOrganizationsLoaded',
      'loadingBrokerOrganizations',
    ]),
    computedOrgs() {
      if (this.brokerOrganizations.length) {
        return this.brokerOrganizations
          .filter(org => {
            const lowerName = org.name ? org.name.toLowerCase() : org.name;
            const searchQuery = this.orgSearchQuery
              ? this.orgSearchQuery.toLowerCase()
              : this.orgSearchQuery;
            const orgs = {
              orgName: searchQuery === '' || lowerName.includes(searchQuery),
            };
            return orgs.orgName;
          })
          .sort((x, y) => {
            return Date.parse(y.createdAt) - Date.parse(x.createdAt);
          });
      }
      return [];
    },
  },
  methods: {
    ...mapActions('brokerOrganizations', [
      'fetchBrokerOrganizations',
      'createBrokerOrganization',
      'activateBrokerAdmin',
      'removeBrokerAdmin',
      'updateBrokerOrganization',
      'updateBrokerAdmin',
      'resendAdminInviteToBrokerOrganization',
      'updatePolicyInformation',
    ]),
    ...mapActions('app', [
      'openSidebarInfo',
      'showUpdateOrgDialog',
      'hideUpdateOrgDialog',
      'hideUpdatePolicyDialog',
    ]),
    handleShowOrgCreate() {
      this.showUpdateOrgDialog({
        content: 'brokerOrganization',
        type: 'create',
      });
    },
    handleCloseOrgForm() {
      this.hideUpdateOrgDialog();
    },
    handleCloseBrokerAdminDialog() {
      this.hideUpdateOrgDialog();
    },
    handleClosePolicyInformationDialog() {
      this.hideUpdatePolicyDialog();
    },
    async handleEditBrokerAdmin({ adminId, payload }) {
      try {
        const { id: brokerId, users: orgUsers } = this.sidebarContent;
        const adminMatchInOrgIndex = orgUsers.findIndex(user => user.id === adminId);
        if (adminMatchInOrgIndex === -1) {
          throw new Error("The admin you're trying to update doesn't belong to this organization");
        }
        this.orgLoading = true;
        await this.updateBrokerAdmin({
          adminId,
          brokerId,
          payload,
        });
        this.orgLoading = false;
        this.handleCloseBrokerAdminDialog();
      } catch (error) {
        this.orgLoading = false;
        this.$dialog.error({ error });
      }
    },
    async handleEditPolicyInformation({ id, payload, callback }) {
      try {
        if (
          moment(payload.expiredDate, 'YYYY-MM-DD').isBefore(payload.effectiveDate, 'YYYY-MM-DD')
        ) {
          throw new Error('The expiration date must be not earlier than the effective date');
        }
        this.orgLoading = true;
        const success = await this.updatePolicyInformation({
          id,
          payload: {
            policyNumber: payload.policyNumber,
            effectiveDate: moment(payload.effectiveDate).format('MM/DD/YYYY'),
            expiredDate: moment(payload.expiredDate).format('MM/DD/YYYY'),
            carrier: payload.carrier,
            deductible: payload.deductible,
            coinsurance: payload.coinsurance,
            benefitPeriod: payload.benefitPeriod,
            perInjuryLimit: payload.perInjuryLimit,
            physicalTherapySublimit: payload.physicalTherapySublimit,
            tpaName: payload.tpaName,
            email: payload.email,
            brokerId: payload.brokerId,
          },
        });
        if (!success) {
          throw new Error("Couldn't edit a policy information to this organization.");
        }

        this.$dialog.info({
          title: 'Success',
          description: 'You have successfully edited a policy information',
        });
        this.handleClosePolicyInformationDialog();
        callback();
      } catch (error) {
        this.$dialog.error({ error });
      } finally {
        this.orgLoading = false;
      }
    },
    async handleActivateBrokerAdmin(adminId) {
      try {
        const { id: brokerId, users: orgUsers } = this.sidebarContent;
        const adminMatchInOrgIndex = orgUsers.findIndex(user => user.id === adminId);
        if (adminMatchInOrgIndex === -1) {
          throw new Error("The admin you're trying to remove doesn't belong to this organization");
        }
        const consentToRemove = await this.$dialog.confirm({
          description: this.$t('messages.activateUserConfirmation'),
        });
        if (!consentToRemove) {
          return;
        }
        this.orgLoading = true;
        await this.activateBrokerAdmin({
          adminId,
          brokerId,
        });
        this.orgLoading = false;
        this.handleCloseBrokerAdminDialog();
      } catch (error) {
        this.orgLoading = false;
        this.$dialog.error({ error });
      }
    },
    async handleRemoveBrokerAdmin(adminId) {
      try {
        const { id: brokerId, users: orgUsers } = this.sidebarContent;
        const adminMatchInOrgIndex = orgUsers.findIndex(user => user.id === adminId);
        if (adminMatchInOrgIndex === -1) {
          throw new Error("The admin you're trying to remove doesn't belong to this organization");
        }
        const consentToRemove = await this.$dialog.confirm({
          description: this.$t('messages.deactivateUserConfirmation'),
        });
        if (!consentToRemove) {
          return;
        }
        this.orgLoading = true;
        await this.removeBrokerAdmin({
          adminId,
          brokerId,
        });
        this.orgLoading = false;
        this.handleCloseBrokerAdminDialog();
      } catch (error) {
        this.orgLoading = false;
        this.$dialog.error({ error });
      }
    },
    async handleResendInvite(email) {
      try {
        const success = await this.resendAdminInviteToBrokerOrganization({ email });
        if (success) {
          this.$dialog.info({
            title: 'Success',
            description: 'You have successfully re-sent the invitation.',
          });
        } else {
          this.$dialog.error({ error: 'failed' });
        }
      } catch (error) {
        this.$dialog.error({ error });
      }
    },
    handleShowOrganization(id) {
      this.openSidebarInfo({
        id,
        content: 'brokerOrganization',
      });
    },
    handleSubmitOrgForm() {
      this.orgLoading = true;
      const clonedForm = JSON.parse(JSON.stringify(this.brokerOrgForm));
      if (this.brokerOrgForm.logoId && this.brokerOrgForm.logoId.id) {
        clonedForm.logoId = this.brokerOrgForm.logoId.id;
      } else {
        clonedForm.logoId = '';
      }
      if (this.updateOrgDialog.type === 'edit') {
        this.updateBrokerOrganization({
          id: this.infoSidebarData.id,
          form: clonedForm,
        }).then(() => {
          this.orgLoading = false;
          this.hideUpdateOrgDialog();
        });
      } else {
        this.createBrokerOrganization(clonedForm).then(() => {
          this.hideUpdateOrgDialog();
          this.orgLoading = false;
        });
      }
    },
    handlePrepareContentToEdit() {
      this.handlePrepareOrgToEdit();
    },
  },
  mounted() {
    this.fetchBrokerOrganizations();
  },
};
</script>

<style lang="scss">
@import '~@/scss/views/organizations';
</style>
