<template>
  <PhDialog
    :value="show"
    fullscreen
    :shadowContentScroll="true"
    @input="closeBrokerAdminModal"
    closeBtnText="ADD"
    hideFooter
    class="broker-admin-modal"
  >
    <h1 class="broker-admin-modal__header" slot="header">
      Broker Admins
    </h1>
    <div slot="content" class="broker-admin-modal__content">
      <BrokerAdminForm
        v-if="!!editAdminId"
        v-bind.sync="editForm"
        @close="hideEditForm"
        @submit="submitEditForm"
        :loading="loading"
        disable-pagination
      />
      <div class="broker-admin-modal__table">
        <PhTable :headers="headers" :items="brokerAdmins">
          <template v-slot:item="{ item }">
            <tr class="broker-admin-modal__table-row">
              <td
                class="broker-admin-modal__table-row-cell"
                :class="{ 'broker-admin-modal__table-row-cell--disabled': !item.active }"
              >
                <span class="broker-admin-modal__table-row-cell-content">
                  {{ item.name }}
                </span>
              </td>
              <td
                class="broker-admin-modal__table-row-cell"
                :class="{ 'broker-admin-modal__table-row-cell--disabled': !item.active }"
              >
                <span class="broker-admin-modal__table-row-cell-content">
                  {{ item.email }}
                </span>
              </td>
              <td
                class="broker-admin-modal__table-row-cell broker-admin-modal__table-row-cell--action"
              >
                <span
                  class="broker-admin-modal__table-row-cell-content broker-admin-modal__table-row-cell-content--justify-end"
                >
                  <v-icon
                    title="re-send invite"
                    @click="handleResendInvite(item.email)"
                    :disabled="!item.active"
                    size="20"
                    class="mx-2"
                  >
                    mdi-email-send-outline
                  </v-icon>
                  <v-icon
                    title="edit"
                    @click="handleEditBrokerAdmin(item)"
                    :disabled="!item.active"
                    size="20"
                    class="mx-2"
                  >
                    edit
                  </v-icon>
                  <v-icon
                    v-if="!item.active"
                    title="activate"
                    @click="handleActivateBrokerAdmin(item.id)"
                    :disabled="item.active"
                    size="20"
                    class="mx-2"
                  >
                    mdi-account-plus-outline
                  </v-icon>
                  <v-icon
                    v-if="item.active"
                    title="deactivate"
                    @click="handleRemoveBrokerAdmin(item.id)"
                    :disabled="!item.active"
                    size="20"
                    class="mx-2"
                  >
                    mdi-account-off-outline
                  </v-icon>
                </span>
              </td>
            </tr>
          </template>
        </PhTable>
      </div>
    </div>
  </PhDialog>
</template>

<script>
import PhDialog from '@/components/modals/PhDialog';
import PhTable from '@/components/tables/PhTable';
import BrokerAdminForm from '@/components/forms/BrokerAdminForm';

const HEADERS = [
  {
    text: 'Name',
    value: 'name',
    width: 150,
  },
  {
    text: 'Email',
    value: 'email',
    sortable: false,
    width: 150,
  },
  {
    text: '',
    value: 'action',
    sortable: false,
    width: 30,
  },
];

const BA_FORM = {
  name: '',
  email: '',
};

export default {
  name: 'BrokerAdminDialog',
  props: {
    show: Boolean,
    loading: Boolean,
    brokerAdmins: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    BrokerAdminForm,
    PhDialog,
    PhTable,
  },
  data: () => ({
    editAdminId: null,
    editForm: { ...BA_FORM },
    headers: HEADERS,
  }),
  methods: {
    hideEditForm() {
      this.editAdminId = null;
    },
    showEditForm(adminId) {
      this.editAdminId = adminId;
    },
    closeBrokerAdminModal() {
      this.$emit('closeDialog');
    },
    submitEditForm() {
      this.$emit('editAdmin', {
        adminId: this.editAdminId,
        payload: { ...this.editForm },
      });

      this.hideEditForm();
      this.resetEditForm();
    },
    handleEditBrokerAdmin(admin) {
      const { id, name, email } = admin;
      this.editForm = {
        ...this.editForm,
        name,
        email,
      };
      this.showEditForm(id);
    },
    handleResendInvite(email) {
      this.$emit('resendInvite', email);
    },
    handleActivateBrokerAdmin(id) {
      this.$emit('activateAdmin', id);
    },
    handleRemoveBrokerAdmin(id) {
      this.$emit('removeAdmin', id);
    },
    resetEditForm() {
      this.editForm = Object.assign({}, { ...BA_FORM });
    },
  },
};
</script>

<style lang="scss">
$block: 'broker-admin-modal';

.#{$block} {
  &__form-title {
    text-align: left;
  }
  &__content {
    height: 100%;
    overflow-y: auto;
  }
  &__header {
    text-transform: capitalize;
  }
  &__table {
    &-row {
      &-cell {
        &-content {
          display: flex;
          &--justify-end {
            display: flex;
            justify-content: flex-end;
          }
        }
        &--disabled {
          pointer-events: none;
          opacity: 0.65;
        }
      }
    }
  }
}
</style>
